import React, { } from "react";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { API_BASE } from "../../constants";


const RibbonsThumb = (props) => {

  const dispatch = useDispatch();
  const idSplit = props.id.split("|")
  const id = idSplit[2]

  const onRibbonThumbClickHandler = (event) => {
    dispatch(
      cartActions.setRibbonsThumbChosen()
    );
    event.stopPropagation();
  }

  const onDeleteRibbonHandler = (event) => {
    dispatch(
      cartActions.removeRibbonFromCart()
    );
    event.stopPropagation();
  }

  return <React.Fragment>
    <div className="thumb" id={`gallery_item_ribbons_${id}`}>
      <a href={() => false} onClick={onDeleteRibbonHandler} id={`removeRibbon#${id}`} className="remove">Smazat</a>
      <a href={() => false}>
        <img src={`${API_BASE}/ribbons/getPicture/${id}/100`} alt="" onClick={onRibbonThumbClickHandler} />
      </a>
    </div>
  </React.Fragment>;
};

export default RibbonsThumb;
