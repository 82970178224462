import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import TextilItem from './TextilItem';
import { cartActions } from '../../store/cart-slice';
import { useDispatch, useSelector } from 'react-redux';
import paramsService from '../../services/params.service';
import textilService from '../../services/textil';
import InitialDialog from '../UI/InitialDialog';
import { useNavigate } from "react-router-dom";

const TextilList = (props) => {
    const { catId } = useParams();
    const [textil, setTextil] = useState([]);
    const [excludedTextil, setExcludedTextil] = useState([]);
    const [categoryLabel, setCategoryLabel] = useState()
    const [showInitialMessage, setShowInitialMessage] = useState(false)
    const [initialMessage, setInitialMessage] = useState("")
    const dispatch = useDispatch();
    const cart = useSelector((state) => state?.cart);
    let history = useNavigate();
    const show = true;

    const fetchTextil = useCallback(async () => {
        if (catId !== undefined) {
            setTextil(await textilService.getProductsFromCategory(catId));
            switch (catId) {
                case "4":
                    setCategoryLabel('Trička')
                    break;
                case "5":
                    setCategoryLabel('Mikiny')
                    break;
                case "7":
                    setCategoryLabel('Ostatní')
                    break;
                case "8":
                    setCategoryLabel('Dětské')
                    break;
                default:
                    break;
            }

        } else {
            setTextil(await textilService.getProductsFromCategory(4));
            setCategoryLabel('TOP')

        }
        const excludedTextils = await textilService.getAllExcludedTextil();


        const groupedByTextil = excludedTextils.reduce((textilSoFar, { textil_id, barva_id, velikost_id }) => {
            if (!textilSoFar[textil_id]) textilSoFar[textil_id] = [];
            textilSoFar[textil_id].push(barva_id + '-' + velikost_id);
            return textilSoFar;
        }, {});
        setExcludedTextil(groupedByTextil);
    }, [catId]);

    const fetchParams = useCallback(async () => {
        const paramsData = await paramsService.getAllParams();
        const intialMessageParam = paramsData?.find((item) => item.code === 'INITIAL_MESSAGE')
        const today = parseInt(new Date().getTime() / 100000)
        const startDate = new Date(intialMessageParam.dvalue)
        const intialMessageValidStartDate = parseInt(new Date(intialMessageParam.dvalue).getTime() / 100000)
        const intialMessageValidEndDate = parseInt(new Date(startDate.setDate(startDate.getDate() + intialMessageParam.nvalue)).getTime() / 100000)
        setInitialMessage(intialMessageParam.cvalue)
        const isInitialMessageActive = (intialMessageValidStartDate < today && today < intialMessageValidEndDate)

        setShowInitialMessage(isInitialMessageActive)
        dispatch(
            cartActions.setData({
                type: 'PARAMS',
                data: paramsData,
            })
        );
    }, [dispatch]);

    const setActivePage = useCallback(() => {
        dispatch(
            cartActions.setActiveStep({
                step: 'ONE',
            })
        );
        if (!cart.stepOneValid) {
            if (cart.totalQuantity < 10) {
                dispatch(
                    cartActions.setWarning({
                        message: 'Minimální počet textilu je 10 kusů.',
                    })
                );
            }
        }
    }, [cart.stepOneValid, cart.totalQuantity, dispatch]);

    const checkVersion = useCallback(async () => {
        const currentVersion = await paramsService.getVersion()

        if (cart?.minimumVersion === undefined || cart?.minimumVersion !== currentVersion) {
            dispatch(cartActions.resetStore({}));
            history('/')
        }
    }, [cart?.minimumVersion, dispatch, history])

    useEffect(() => {
        fetchTextil();
        setActivePage();
        fetchParams();
        checkVersion()
    }, [fetchTextil, fetchParams, setActivePage, checkVersion]);

    return (
        <React.Fragment>
            {showInitialMessage && <InitialDialog open={showInitialMessage} message={initialMessage} />}

            <h2 className="strip n1">{categoryLabel}</h2>
            <div id="products_list">
                {show &&
                    textil.map((oneTextil) => (
                        <TextilItem
                            key={oneTextil.id}
                            textil={oneTextil}
                            excludedTextil={excludedTextil[oneTextil.id]}
                        />
                    ))}
            </div>
        </React.Fragment>
    );
};

export default TextilList;
